import React, { memo, ReactNode, useCallback, useMemo, useState } from "react";
import {
  Button,
  Grid,
  Modal,
  ModalProps,
  TextField,
  useForm,
} from "@app/components";
import { useNotification, useUser } from "@app/providers";
import { APIResponse, approveVehicleOffer } from "@app/api";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";

interface Props extends ModalProps {
  vehicleOfferId?: string;
  isApproved?: boolean;
  onClose: (val?: boolean) => void;
}

interface Data {
  comment: string;
}

const ModalVehicleOfferApproveOrReject = (props: Props) => {
  const { onClose, vehicleOfferId, isApproved, ...restProps } = props;
  const { showNotification } = useNotification();
  const { user } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const { pending, onChange, errors, values, resetForm } = useForm<Data>({
    values: {
      comment: "",
    },
  });

  const onRedirectConfirm = useCallback(async () => {
    if (!vehicleOfferId || !user) {
      return;
    }
    const body = {
      id: vehicleOfferId,
      isApproved: isApproved,
      comment: values.comment,
    };
    setLoading(true);
    approveVehicleOffer(body)
      .then((res) => {
        showNotification({
          variant: "success",
          message: isApproved === true ? "Согласован" : "Отклонен",
        });
        onClose(true);
      })
      .catch((e) => {
        setLoading(false);
        showNotification({
          message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
          variant: "error",
        });
      });
  }, [onClose, showNotification, vehicleOfferId, user, isApproved, values]);

  const actions = useMemo(() => {
    const returnActions: ReactNode[] = [];
    returnActions.push(
      <Button
        text={isApproved ? "Согласовать" : "Отклонить"}
        disabled={!values.comment}
        onClick={onRedirectConfirm}
        showLoader={loading}
      />
    );
    return returnActions;
  }, [loading, onRedirectConfirm, isApproved]);

  const onModalClose = useCallback(() => {
    resetForm();
    onClose();
  }, [onClose, resetForm]);

  return (
    <Modal actions={actions} onClose={onModalClose} {...restProps}>
      <Grid>
        <TextField
          label="Комментарий"
          placeholder="Добавьте комментарий (необязательно)"
          value={values.comment}
          name="comment"
          onChange={onChange}
        />
      </Grid>
    </Modal>
  );
};

export default memo(ModalVehicleOfferApproveOrReject);
