import React from "react";
import { LayoutLanding } from "@app/layouts";
import styled from "styled-components";
import { Container } from "@app/components";
import { Link } from "react-router-dom";
import { theme } from "styled-tools";

const StyledPage = styled.div`
  padding: 40px 0;
  box-sizing: border-box;

  h1 {
    text-align: center;
    margin-bottom: 40px;
  }

  h2 {
    margin: 40px 0 20px 0;
  }

  p {
    font-size: 16px;
    line-height: 20px;
    color: ${theme("color.dark")};

    + p {
      margin-top: 8px;
    }

    + ul {
      margin: 8px 0 0 0;

      + p {
        margin-top: 8px;
      }
    }
  }

  a {
    display: inline-block;
    margin-left: 4px;
    color: ${theme("color.blue")};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

function PagePrivacyPolicy() {
  return (
    <LayoutLanding>
      <StyledPage>
        <Container>
          <h1>
            ПОЛИТИКА О КОНФИДЕНЦИАЛЬНОСТИ И ЗАЩИТЕ ИНФОРМАЦИИ ПОЛЬЗОВАТЕЛЕЙ
          </h1>
          <p>
            Частная компания «CDS Development Ltd.» (далее – компания),
            публикует настоящее Соглашение по адресу
            <Link to="/">https://www.dispatchcds.com</Link> и в мобильном
            приложении DISPATCH., представляющую собой публичную оферту
            (предложение) заключить гражданско-правовой договор на нижеуказанных
            условиях с любым лицом, который примет его условия путем совершения
            указанных в настоящем Соглашении действий.
          </p>
          <h2>ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h2>
          <p>
            <b>
              Приведенные в настоящем Соглашении термины и определения
              истолковываются в соответствии со следующими значениями:
            </b>
          </p>
          <p>
            <b>Публичная оферта</b> – это содержащее все существенные условия
            предложение, из которого усматривается воля лица, делающего
            предложение заключить гражданско-правовой договор на указанных в
            публичной оферте условиях с любым лицом, которое отзовется на это
            предложение (п. 5 ст. 395 Гражданского кодекса Республики
            Казахстан).
          </p>
          <p>
            <b>Приложение</b> - Информационная система, размещенная в сети
            Интернет по адресу <Link to="/">https://www.dispatchcds.com</Link>,
            а также мобильное приложение DISPATCH которая, помимо прочего,
            содержит информацию о перечне и характеристиках Веб-служб.
          </p>
          <p>
            <b>Пользователь</b> – любое физическое или юридическое лицо, которое
            совершило Акцепт настоящего Соглашения.
          </p>
          <p>
            <b>Договор публичной оферты</b> – договор публичной оферты,
            регулирующий взаимоотношения Компании и Пользователя, связанных с
            использованием Приложения и приобретением услуг, размещенный по
            адресу: https://www.dispatchcds.com в настоящем приложении.
          </p>
          <p>
            <b>Акцепт</b> – полное и безоговорочное принятие Пользователем
            публичной оферты – Соглашения в полном объеме, что равносильно
            заключению настоящего Соглашения на установленных в нем условиях.
          </p>
          <p>
            <b>Статус Пользователя</b> – статус «Исполнитель» или «Заказчик»,
            определенный Пользователем при регистрации в Приложении.
          </p>
          <p>
            <b>Исполнитель</b> – Пользователь, зарегистрированный в Приложении в
            статусе «Партнер», являющийся владельцем техники и предоставляющий
            услуги техники на условиях Договора публичной оферты и настоящего
            Соглашения.
          </p>
          <p>
            <b>Заказчик</b> – Пользователь, зарегистрированный в Приложении в
            статусе «Заказчик», приобретающий услуги техники, мониторинга работы
            техники на условиях настоящего Договора публичной оферты и
            настоящего Соглашения.
          </p>
          <p>
            <b>Услуга Компании</b> – платные услуги, оказываемые Компанией
            Пользователям, условия предоставления которых определяются в
            установленном порядке в зависимости от Статуса Пользователя.
          </p>
          <p>
            <b>Услуга Техники</b> – услуги, оказываемые Исполнителем на
            собственной или арендованной спецтехнике по Заказам/Откликам от
            Заказчика.
          </p>
          <p>
            <b>Личный кабинет</b> - персональный раздел Пользователя в
            Приложении, к которому Пользователь получает доступ после
            регистрации в Приложении, содержащий совокупность данных о
            Пользователе и набор функций для зарегистрированных Пользователей
            Приложения.
          </p>
          <p>
            Настоящее Соглашение является официальным документом Компании и
            определяют порядок обработки и защиты информации юридических и
            физических лиц (далее — «Пользователи»), пользующихся услугами
            Приложения.
          </p>
          <p>
            Целью настоящего Соглашения является обеспечение надлежащей
            конфиденциальности и защиты информации о Пользователях от
            несанкционированного доступа и разглашения.
          </p>
          <p>
            Отношения, связанные со сбором, хранением, распространением и
            защитой информации о Пользователях Приложения, регулируются
            настоящим Соглашением и действующим законодательством Республики
            Казахстан.
          </p>
          <p>
            Соглашение является публичным документом, текущая редакция
            Соглашения доступна любому Пользователю при переходе по ссылке
            <Link to="/">https://www.dispatchcds.com</Link>.
          </p>
          <p>
            Компания вправе вносить изменения в Соглашение. При внесении
            изменений в Соглашение Компания уведомляет об этом Пользователей
            путем размещения новой редакции Соглашения Приложении.
          </p>
          <p>
            Регистрируясь и используя Приложение, Пользователь выражает свое
            согласие с условиями Соглашения.
          </p>
          <p>
            <b>
              По условиям настоящего Соглашения информация о Пользователях
              включает в себя:
            </b>
          </p>
          <ul>
            <li>Конфиденциальную информацию.</li>
            <li>Персональные данные Пользователей - физических лиц;</li>
            <li>Сведения о юридическом лице Пользователя;</li>
          </ul>
          <p>
            <b>Конфиденциальная информация включает в себя:</b>
          </p>
          <p>
            Конфиденциальная информация означает любую информацию представляющую
            коммерческую ценность, раскрываемую в письменной, электронной или
            любой другой форме:
          </p>
          <ul>
            <li>
              после заключения Договора публичной оферты и настоящего
              Соглашения;
            </li>
            <li>
              между Заказчиком и Компанией в рамках исполнения Услуг Компании по
              Договору публичной оферты;
            </li>
            <li>
              между Компанией, Исполнителем и Заказчиком в рамках Договора Услуг
              техники;
            </li>
          </ul>
          <p>
            Сторона, принимающая Конфиденциальную информацию, несет
            ответственность за умышленное и неумышленное разглашение, а также
            несанкционированное использование, перед стороной, передающей
            Конфиденциальную информацию.
          </p>
          <p>
            <b>Информацию нельзя считать Конфиденциальной информацией,</b> если
            возможно доказать любое из следующих положений:
          </p>
          <ul>
            <li>
              данная информация была общедоступной на момент ее получения от
              любого Пользователя в целях, определенных Договором публичной
              оферты и настоящим Соглашением, либо впоследствии стала
              общедоступной, но не в результате несанкционированного раскрытия
              Пользователем или Компанией в рамках сотрудничества;
            </li>
            <li>
              Пользователь в письменной форме дал согласие на раскрытие
              информации третьей стороне;
            </li>
            <li>
              данная информация была раскрыта Пользователю или Компании не в
              целях Договора публичной оферты и Соглашения;
            </li>
          </ul>
          <p>
            <b>Персональные данные Пользователей включают в себя:</b>
          </p>
          <ul>
            <li>
              предоставляемые Пользователями и минимально необходимые для
              регистрации в Приложении: персональные данные физических лиц при
              авторизации, включающие ИИН, ФИО, контактные данные, реквизиты
              расчетного счета, открытого в банке второго уровня;
            </li>
            <li>
              Компания вправе, в частности, запросить у Пользователя любые
              документы, а также иную дополнительную информацию, которая, по
              усмотрению Компании, будет являться необходимой и достаточной для
              идентификации такого Пользователя и позволит исключить
              злоупотребления и нарушения прав третьих лиц.
            </li>
          </ul>
          <p>
            <b>Сведения о юридическом лице Пользователя:</b>
          </p>
          <ul>
            <li>
              предоставляемые Пользователями и минимально необходимые для
              регистрации в Приложении: БИН, наименование/вид организации,
              список сотрудников организации с должностями, правоустанавливающие
              документы, справки и сведения о юридическом лице из
              государственной базы, документы, подтверждающие полномочия
              сотрудников;
            </li>
            <li>
              Компания вправе, в частности, запросить у Пользователя любые
              документы, а также иную дополнительную информацию, которая, по
              усмотрению Компании, будет являться необходимой и достаточной для
              идентификации юридических лиц Пользователя и позволит исключить
              злоупотребления и нарушения прав третьих лиц.
            </li>
          </ul>
          <p>
            <b>Условия оказания Услуг Компании и пользования Приложением</b>
          </p>
          <p>
            Оказывая Услуги Компании, Компания, действуя разумно и
            добросовестно, считает, что Пользователь:
          </p>
          <ul>
            <li>
              обладает всеми необходимыми правами, позволяющими ему осуществлять
              Регистрацию, использовать Приложение и Услуги Компании;
            </li>
            <li>
              указывает достоверную информацию о себе в объемах, необходимых для
              использования Приложения и Услуг Компании;
            </li>
            <li>
              осознает, что отдельная информация, размещаемая Пользователем
              Приложении, может становиться доступной для других Пользователей
              Приложения и Пользователей Интернета, может быть скопирована и
              распространена такими Пользователями;
            </li>
            <li>
              ознакомлен с настоящим Соглашением, выражает свое согласие с ними
              и принимает на себя указанные в них права и обязанности.
            </li>
            <li>
              обеспечивает конфиденциальность информации, передаваемой Компании
              или иным Пользователям / получаемой от Компании или иных
              Пользователей.
            </li>
          </ul>
          <p>
            Компания не проверяет достоверность получаемой (собираемой)
            информации о Пользователях, за исключением случаев, когда такая
            проверка необходима в целях исполнения Компанией обязательств по
            Договору публичной оферты.
          </p>
          <p>
            <b>Цели обработки информации</b>
          </p>
          <p>
            Компания осуществляет обработку Информации о Пользователях в целях
            выполнения обязательств Компании перед Пользователями по Договору
            публичной оферты.
          </p>
          <p>
            <b>Иная информация о Пользователях, обрабатываемая Компанией</b>
          </p>
          <p>
            Компания обрабатывает также иную информацию о Пользователях, которая
            включает в себя:
          </p>
          <ul>
            <li>
              стандартные данные, автоматически получаемые http-сервером при
              доступе к Приложению и последующих действиях Пользователя.
              Информация, содержащая историю посещения Приложения Пользователем;
            </li>
            <li>
              информация, автоматически получаемая при доступе к Приложению с
              использованием закладок (cookies);
            </li>
            <li>
              информация, создаваемая Пользователями Приложении: фотографии,
              аудиозаписи, видеозаписи, комментарии;
            </li>
            <li>
              информация, полученная в результате действий других Пользователей
              в Приложении (в частности, отклики на Заказы, Отзывы);
            </li>
            <li>
              координаты, определяющие местонахождение мобильного устройства
              Пользователя, на котором установлено мобильное приложение для
              доступа к Приложению (данные геолокации).
            </li>
          </ul>
          <p>
            <b>Меры по защите Информации о Пользователях</b>
          </p>
          <p>
            Компания принимает технические и организационно-правовые меры в
            целях обеспечения защиты Информации о Пользователях от
            неправомерного или случайного доступа к ним, уничтожения, изменения,
            блокирования, копирования, распространения, а также от иных
            неправомерных действий.
          </p>
          <p>
            Для авторизации доступа к Приложению используется Логин (номер
            телефона) и Пароль Пользователя. Ответственность за сохранность
            данной информации несет Пользователь. Пользователь не вправе
            передавать собственный Логин и Пароль третьим лицам, а также обязан
            предпринимать меры по обеспечению их защиты и конфиденциальности.
          </p>
          <p>
            <b>Обработка информации о Пользователях</b> осуществляется Компанией
            на основе принципов:
          </p>
          <ul>
            <li>законности целей и способов обработки информации;</li>
            <li>добросовестности;</li>
            <li>
              соответствия объема и характера обрабатываемых данных, способов
              обработки данных целям обработки данных;
            </li>
          </ul>
          <p>
            <b>Условия и цели обработки Информации о Пользователях</b>
          </p>
          <p>
            Компания осуществляет обработку Информации о Пользователях в целях
            исполнения Договора публичной оферты между Компанией и Пользователем
            на оказание Услуг Компании. Компания использует информацию
            исключительно для обеспечения ему качественного оказания услуг и их
            учета.
          </p>
          <p>
            <b>Сбор персональных данных</b>
          </p>
          <p>
            Сбор Информации о Пользователях осуществляется в Приложении при
            Регистрации Пользователя, при внесении Пользователем по своей
            инициативе дополнительных сведений о себе в Приложении, а также в
            дальнейшем при обсуждении вопросов сотрудничества между Компанией,
            Пользователем – Заказчиком и Пользователем – Исполнителем.
          </p>
          <p>
            <b>Передача персональных данных</b>
          </p>
          <p>
            Компания не раскрывает персональные данные одних Пользователей
            Приложения другим Пользователям Приложения, за исключением случаев,
            когда информация используется Пользователями для осуществления
            деятельности в рамках процессов, регулируемых в приложении.
          </p>
          <p>
            Компания никогда не публикует персональные данные в открытом доступе
            и не передает ее третьим лицам.
          </p>
          <p>
            Исключением являются лишь ситуации, когда предоставление такой
            информации уполномоченным государственным органам предписано
            действующим законодательством Республики Казахстан.
          </p>
          <p>
            Компания публикует и распространяет только отчеты, построенные на
            основании собранных анонимных данных. При этом отчеты не содержат
            информацию, по которой было бы возможным идентифицировать
            персонифицированные данные пользователей услуг.
          </p>
          <p>
            Компания также использует анонимные данные для внутреннего анализа,
            целью которого является развитие продуктов и услуг Приложения, за
            исключением случаев ввода не анонимных данных (Оценка
            пользователей).
          </p>
          <p>
            <b>Заключительные положения</b>
          </p>
          <p>
            В случае возникновения любых споров или разногласий, связанных с
            исполнением настоящего Соглашения, Пользователь и Компания приложат
            все усилия для их разрешения путем проведения переговоров между
            ними. В случае, если споры не будут разрешены путем переговоров,
            споры подлежат разрешению в порядке, установленном действующим
            законодательством Республики Казахстан.
          </p>
          <p>
            Компания делает все возможное для соблюдения настоящего Соглашения,
            однако, не может гарантировать сохранность информации в случае
            воздействия факторов, находящихся вне влияния Компании, результатом
            действия которых станет раскрытие информации.
          </p>
          <p>
            Настоящее Соглашение вступает в силу для Пользователя с момента
            Акцепта настоящего Соглашения.
          </p>
          <p>
            Компания не несет ответственности за неблагоприятные последствия, а
            также за любые убытки, причиненные вследствие ограничения доступа к
            Приложению или вследствие посещения Приложения и использования
            размещенной на нем информации.
          </p>
          <p>
            <b>Ссылки</b>
          </p>
          <p>
            Приложение может содержать ссылки на другие сайты и приложения, не
            имеющие отношения к Компании и принадлежащие третьим лицам.
          </p>
          <p>
            Компания не несет ответственности за точность, полноту и
            достоверность сведений, размещенных на Приложениях третьих лиц, и не
            берет на себя никаких обязательств по сохранению конфиденциальности
            информации, оставленной Пользователями на таких Приложениях.
          </p>
          <p>
            <b>Обращения пользователей</b>
          </p>
          <p>
            По вопросам, касающимся настоящего Соглашения, просьба обращаться по
            адресу:
            <a href="mailto:support@dispatchcds.com">support@dispatchcds.com</a>
            .
          </p>
        </Container>
      </StyledPage>
    </LayoutLanding>
  );
}

export default PagePrivacyPolicy;
