import React, { memo, useCallback, useMemo } from "react";
import { Grid, Info, Paper } from "@app/components";
import styled from "styled-components";
import { Company } from "@app/models";
import { IconInfo16, IconPencil24, IconTrash24, IconUsers24 } from "@app/icons";
import MoreMenu from "../MoreMenu";
import CompanyStatusChip from "../CompanyStatusChip";
import { getDictionaryName } from "@app/helpers";
import { theme } from "styled-tools";

interface Props {
  company: Company;
  onClickEdit: (organization: Company) => void;
  onClickDelete: (organization: Company) => void;
  onClickEmployees: (organization: Company) => void;
  onPaperClick?: (organization: Company) => void;
}

const CardHeader = styled.div`
  display: flex;
`;

const CardContent = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  box-sizing: border-box;
  border-top: 1px solid #d8d8d8;
`;

const CompanyName = styled.p`
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.color.dark};
  flex-grow: 1;
  flex-shrink: 1;
`;

const ActionBar = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  margin: -4px -4px 0 8px;
`;

const Employees = styled.div`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.grayDark};
`;

const EmployeesText = styled.p`
  margin: 0 0 0 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${(props) => props.theme.color.grayDark};
`;

const TooltipContainer = styled.div`
  display: none;
  position: absolute;
  width: 150px;
  font-size: 14px;
  min-height: 20px;
  background-color: ${theme("color.dark")};
  color: white;
  padding: 5px;
  border-radius: 5px;
  z-index: 999;
  box-sizing: border-box;
  margin-top: 2px;
  word-break: break-word;

  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  word-wrap: break-word;

  top: 100%;
  left: 0;
  transform: translateX(-50%);
`;

const InfoIcon = styled.div`
  display: flex;
  height: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: ${theme("color.dark")};
  cursor: pointer;
  position: relative;
  margin-left: 8px;
  z-index: 999;

  &:hover ${TooltipContainer} {
    display: flex;
  }
`;

function CardCompany(props: Props) {
  const {
    company,
    onClickEdit,
    onClickDelete,
    onClickEmployees,
    onPaperClick,
  } = props;

  const onClickEditButton = useCallback(() => {
    onClickEdit(company);
  }, [onClickEdit, company]);

  const onPaperClickHandler = useCallback(() => {
    if (onPaperClick) {
      onPaperClick(company);
    }
  }, [onPaperClick, company]);

  const onClickDeleteButton = useCallback(() => {
    onClickDelete(company);
  }, [onClickDelete, company]);

  const onClickEmployeesText = useCallback(() => {
    onClickEmployees(company);
  }, [company, onClickEmployees]);

  const menuItems = useMemo(
    () => [
      {
        icon: IconPencil24,
        label: "Редактировать",
        onClick: onClickEditButton,
      },
      { icon: IconTrash24, label: "Удалить", onClick: onClickDeleteButton },
    ],
    [onClickEditButton, onClickDeleteButton]
  );

  return (
    <Paper onClick={onPaperClickHandler}>
      <CardHeader>
        <CompanyName>{company.name}</CompanyName>
        <CompanyStatusChip status={company.companyStatus} />
        {company.companyStatus === 3 && (
          <InfoIcon>
            <IconInfo16 />
            <TooltipContainer>{company.comment}</TooltipContainer>
          </InfoIcon>
        )}
        <ActionBar>
          <MoreMenu items={menuItems} />
        </ActionBar>
      </CardHeader>
      <Employees>
        <IconUsers24 />
        <EmployeesText onClick={onClickEmployeesText}>
          Сотрудники ({company.employeesCount})
        </EmployeesText>
      </Employees>
      <CardContent>
        <Grid>
          <Info
            label="Форма организации"
            value={getDictionaryName(company.companyType, "-")}
          />
          <Info label="ИИН/БИН" value={company.bin} />
          <Info
            label="Ставка НДС"
            value={getDictionaryName(company.nds, "-")}
          />
        </Grid>
      </CardContent>
    </Paper>
  );
}

export default memo(CardCompany);
