import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { AlertBox, Button, Grid, Toolbar } from "@app/components";
import { LayoutDefault } from "@app/layouts";
import { IconInfo20, IconPlus20 } from "@app/icons";
import { Company } from "@app/models";
import { APIResponse, getMyCompanies } from "@app/api";
import { CardCompany } from "@app/common";
import styled from "styled-components";
import {
  ModalAddOrganizationInstruction,
  ModalCompanyDetail,
  ModalJoinAnOrganization,
  ModalOrganizationEdit,
} from "@app/modals";
import { useNotification } from "@app/providers";
import { getAxiosErrorMessage } from "@app/helpers";
import { AxiosError } from "axios";
import ModalOrganizationCreate from "../../modals/OrganizationCreate";
import ModalOrganizationInformation from "../../modals/OrganizationInformation";

interface Props {}

const Wrapper = styled.div`
  box-sizing: border-box;
  padding: 16px;
`;

const AlertRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  gap: 16px;
  max-width: 1256px;
`;

function PageOrganizations(props: Props) {
  const { showNotification } = useNotification();
  const [pending, setPending] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [joinAnOrganizationModalVisible, setJoinAnOrganizationModalVisible] =
    useState<boolean>(false);
  const [companyCreateModalVisible, setCompanyCreateModalVisible] =
    useState<boolean>(false);
  const [companyEditModalVisible, setCompanyEditModalVisible] =
    useState<boolean>(false);
  const [companyDetailModalVisible, setCompanyDetailModalVisible] =
    useState<boolean>(false);
  const [organizations, setOrganizations] = useState<Company[]>([]);
  const [organization, setOrganization] = useState<Company | null>(null);
  const [organizationForCreateModal, setOrganizationForCreateModal] =
    useState<Company | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [instructionModalVisible, setInstructionModalVisible] =
    useState<boolean>(false);
  const [readonly, setReadonly] = useState<boolean>(true);

  const onAlertClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const onModalClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const onClickAddOrganization = useCallback(() => {
    setOrganizationForCreateModal(null);
    setCompanyCreateModalVisible(true);
  }, []);

  const onClickInstruction = useCallback(() => {
    setInstructionModalVisible(true);
  }, []);

  const closeCompanyCreateModal = useCallback(() => {
    setOrganization(null);
    setCompanyCreateModalVisible(false);
  }, []);

  const closeCompanyEditModal = useCallback(() => {
    setOrganization(null);
    setCompanyEditModalVisible(false);
  }, []);

  const closeJoinAnOrganizationModal = useCallback(() => {
    setJoinAnOrganizationModalVisible(false);
  }, []);

  const closeInstructionModal = useCallback(() => {
    setInstructionModalVisible(false);
  }, []);

  const onClickJoinAnOrganization = useCallback(() => {
    setJoinAnOrganizationModalVisible(true);
  }, []);

  const onClickEdit = useCallback((organization: Company) => {
    setOrganizationForCreateModal(organization);
    setReadonly(false);
    setCompanyEditModalVisible(true);
  }, []);

  const onClickEmployees = useCallback((organization: Company) => {
    setOrganization(organization);
    setCompanyDetailModalVisible(true);
  }, []);

  const onPaperClick = useCallback((organization: Company) => {
    setOrganizationForCreateModal(organization);
    setReadonly(true);
    setCompanyEditModalVisible(true);
  }, []);

  const onClickDelete = useCallback((organization: Company) => {
    setOrganization(organization);
  }, []);

  const onCloseCompanyDetail = useCallback(() => {
    setOrganization(null);
    setCompanyDetailModalVisible(false);
  }, []);

  const getData = useCallback(async () => {
    try {
      const response = await getMyCompanies();

      setPending(false);

      if (!response.succeeded) {
        showNotification({
          variant: "error",
          message: response.message as string,
        });

        setError(true);

        return;
      }

      setOrganizations(response.data);
      setError(false);
    } catch (e) {
      setPending(false);
      setError(true);

      showNotification({
        message: getAxiosErrorMessage(e as AxiosError<APIResponse>),
        variant: "error",
      });
    }
  }, [showNotification]);

  const leftControls = useMemo<ReactNode[]>(
    () => [
      <Button
        text="Присоединиться к организации"
        onClick={onClickJoinAnOrganization}
        variant="outlined"
      />,
      <Button
        text="Добавить организацию"
        startIcon={IconPlus20}
        onClick={onClickAddOrganization}
      />,
    ],
    [onClickAddOrganization, onClickJoinAnOrganization]
  );
  const rightControls = useMemo<ReactNode[]>(
    () => [
      <Button
        text="Смотреть инструкцию"
        variant="newDesign"
        startIcon={IconInfo20}
        onClick={onClickInstruction}
      />,
    ],
    [onClickInstruction]
  );

  const bottomControls = useMemo(
    () => [
      <AlertRow>
        <AlertBox
          title="Добавление Организации занимает до одного рабочего дня."
          description="В случае отсутствия обратной связи в течение одного рабочего дня
            просим обращаться по номеру телефона: +7 771 046 0533"
          onClick={onAlertClick}
        />
      </AlertRow>,
    ],
    [onAlertClick]
  );

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <LayoutDefault title="Мои организации">
      <Toolbar
        leftControls={leftControls}
        rightControls={rightControls}
        bottomControls={bottomControls}
      />
      <Wrapper>
        <Grid columns={2}>
          {organizations.map((organization) => (
            <CardCompany
              company={organization}
              key={organization.id}
              onClickDelete={onClickDelete}
              onClickEdit={onClickEdit}
              onClickEmployees={onClickEmployees}
              onPaperClick={onPaperClick}
            />
          ))}
        </Grid>
      </Wrapper>

      <ModalOrganizationCreate
        open={companyCreateModalVisible}
        size={"medium"}
        data={organizationForCreateModal}
        onClose={closeCompanyCreateModal}
      />
      <ModalOrganizationEdit
        open={companyEditModalVisible}
        size={"large"}
        data={organizationForCreateModal}
        onClose={closeCompanyEditModal}
        readonly={readonly}
      />
      <ModalCompanyDetail
        title="Сотрудники"
        company={organization}
        open={companyDetailModalVisible}
        onClose={onCloseCompanyDetail}
      />
      <ModalJoinAnOrganization
        open={joinAnOrganizationModalVisible}
        onClose={closeJoinAnOrganizationModal}
      />
      <ModalAddOrganizationInstruction
        open={instructionModalVisible}
        onClose={closeInstructionModal}
      />

      <ModalOrganizationInformation open={showModal} onClose={onModalClose} />
    </LayoutDefault>
  );
}

export default memo(PageOrganizations);
