import axios, { AxiosHeaders, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import qs from "qs";
import { refreshTokens } from "./user";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

const refreshAndRetryQueue: RetryQueueItem[] = [];

let isRefreshing = false;

const axiosInstance = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1`,
  paramsSerializer: {
    serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
  },
});

function instance(authorization = true) {
  if (authorization) {
    axiosInstance.interceptors.request.use(
      async (config) => {
        if (config.headers) {
          (config.headers as AxiosHeaders).set(
            "Authorization",
            `Bearer ${Cookies.get("accessToken") || ""}`
          );
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest: AxiosRequestConfig = error.config;

        if (error.response && error.response.status === 401) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const refreshToken = Cookies.get("refreshToken") ?? "";
              const response = await refreshTokens({ refreshToken });
              const { token, refreshToken: newRefreshToken } = response;

              Cookies.set("accessToken", token);
              Cookies.set("refreshToken", newRefreshToken);

              error.config.headers["Authorization"] = `Bearer ${token}`;

              refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
                axiosInstance
                  .request(config)
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              refreshAndRetryQueue.length = 0;

              return axiosInstance(originalRequest);
            } catch (refreshError) {
              console.error("Token refresh failed:", refreshError);

              Cookies.remove("accessToken");
              Cookies.remove("refreshToken");
              refreshAndRetryQueue.length = 0;
              window.location.href = "/sign-in";
              return Promise.reject(refreshError);
            } finally {
              isRefreshing = false;
            }
          }

          return new Promise<void>((resolve, reject) => {
            refreshAndRetryQueue.push({
              config: originalRequest,
              resolve,
              reject,
            });
          });
        }

        return Promise.reject(error);
      }
    );
  }

  return axiosInstance;
}

export default instance;
