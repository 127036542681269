import { APIResponse, PageableParams, PageableResponse } from "./interfaces";
import {
  Approver,
  SmartContract,
  SmartContractAdditionalReason,
  SmartContractVehicle,
  SmartContractVehicleType,
} from "@app/models";
import instance from "./instance";

interface CreateSmartContractDTO {
  customerId: string;
  projectId: string;
  partnerId: string;
  contractVehicleTypeId: SmartContractVehicleType;
  ndsId: string;
  parentId: string;
  additionalContractReasonIds: string[];
}

interface AddSmartContractRequisitesDTO {
  draftId: string;
  contractDate: string;
  startDate: string;
  endDate: string;
  customerSignerId: string;
  partnerSignerId: string;
  customerBankAccountId: string;
  partnerBankAccountId: string;
}

export interface AddSmartContractPaymentCalculationsDTO {
  draftId: string;
  advancePaymentType: number;
  advancePaymentAmount?: number;
  amount: number;
  collateralTypeId: number;
}

export interface SmartContractVehicleDTO {
  vehicleId?: string | null;
  requestId?: string | null;
  vehicleTypeId: string | null;
  serviceTypeId: string | null;
  characteristicsId: string | null;
  workShiftId: string | null;
  unitId: string | null;
  nomenclatureId: string | null;
  startDate: string;
  endDate: string;
  height: number | null;
  craneBoomReach: number | null;
  volume: number;
  loadCapacity: number | null;
  price: number;
  priceWithoutNds: number;
  totalPrice: number;
  totalPriceWithoutNds: number;
  requestType: 1 | 2;
}

export interface GetSmartContractVehiclesDTO {
  customerId: string;
  projectId: string;
  partnerId?: string;
  vehicleType?: number;
}

export interface AddSmartContractVehiclesDTO {
  draftId: string;
  vehicleItems: SmartContractVehicleDTO[];
}

export interface SignSmartContractDTO {
  id: string;
  signedFile: string;
}

interface SignSmartContractFileDTO {
  fileId: string;
  signedFile: string;
}

export interface SignSmartContractV2DTO {
  id: string;
  signedFiles: SignSmartContractFileDTO[];
}

export interface SendToApprovalDTO {
  id: string;
  customerUserIds?: string[];
  partnerUserIds?: string[];
  notificationRecipients?: string[];
}

export interface ApprovalUser {
  userId: string;
  ord: number;
}

export interface SendToOrderedApprovalDTO {
  id: string;
  isOrderedApproval: boolean;
  approvalUsers: ApprovalUser[] | null;
  notificationRecipients?: string[];
}

export interface GetSmartContractsDTO {
  customerIds?: string[];
  projectIds?: string[];
  partnerIds?: string[];
  statusIds?: string[];
}

export function getSmartContracts(
  params: PageableParams<SmartContract> & GetSmartContractsDTO
): Promise<PageableResponse<SmartContract>> {
  return instance()
    .get("/contractDraft", {
      params,
    })
    .then((response) => response.data);
}

export function getSmartContract(
  id: string
): Promise<APIResponse<SmartContract>> {
  return instance()
    .get(`/contractDraft/${id}`)
    .then((response) => response.data);
}

export function createSmartContract(
  data: CreateSmartContractDTO
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/create", data)
    .then((response) => response.data);
}

export function deleteSmartContract(id: string): Promise<APIResponse<boolean>> {
  return instance()
    .delete(`/contractDraft/${id}`)
    .then((response) => response.data);
}

export function addSmartContractRequisites(
  data: AddSmartContractRequisitesDTO
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/addRequisites", data)
    .then((response) => response.data);
}

export function addSmartContractPaymentCalculations(
  data: AddSmartContractPaymentCalculationsDTO
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/addPaymentCalculations", data)
    .then((response) => response.data);
}

export function getSmartContractVehicles(
  params: GetSmartContractVehiclesDTO
): Promise<APIResponse<SmartContractVehicle[]>> {
  return instance()
    .get("/contractDraft/getVehicleItems", {
      params,
    })
    .then((response) => response.data);
}

export function getAdditionalContractReason(): Promise<
  APIResponse<SmartContractAdditionalReason[]>
> {
  return instance()
    .get("/additionalContractReason/short")
    .then((response) => response.data);
}

export function addSmartContractVehicles(
  data: AddSmartContractVehiclesDTO
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/addVehicles", data)
    .then((response) => response.data);
}

export function generateSmartContractFile(
  id: string
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/generateFile", {
      id,
    })
    .then((response) => response.data);
}

export function attachSmartContractFile(
  id: string,
  file: File
): Promise<APIResponse<SmartContract>> {
  const formData = new FormData();
  formData.append("id", id);
  formData.append("file", file);
  return instance()
    .post("/contractDraft/AttchContractFile", formData)
    .then((response) => response.data);
}

export function sendToSignSmartContractFile(
  id: string
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/sendToSign", {
      id,
    })
    .then((response) => response.data);
}

export function sendToApprovalSmartContract(
  data: SendToApprovalDTO
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/sendToApproval", data)
    .then((response) => response.data);
}

export function sendToOrderedApprovalContract(
  data: SendToOrderedApprovalDTO
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/sendToOrderedApproval", data)
    .then((response) => response.data);
}

export function recallApprovalSmartContract(
  id: string
): Promise<APIResponse<SmartContract>> {
  return instance()
    .post("/contractDraft/recallApproval", { id })
    .then((response) => response.data);
}

export function signSmartContract(
  data: SignSmartContractDTO
): Promise<APIResponse<unknown>> {
  return instance()
    .post(`/contractDraft/sign/`, data)
    .then((response) => response.data);
}

export function signMultiSmartContract(
  data: SignSmartContractV2DTO
): Promise<APIResponse<unknown>> {
  return instance()
    .post(`/contractDraft/sign-multiple/`, data)
    .then((response) => response.data);
}

export function signXmlSmartContract(
  data: SignSmartContractDTO
): Promise<APIResponse<unknown>> {
  return instance()
    .post(`/contractDraft/sign-xml/`, data)
    .then((response) => response.data);
}

export function getXmlSignFile(
  id: SmartContract["id"]
): Promise<APIResponse<unknown | null>> {
  return instance()
    .get(`/contractDraft/${id}/get-xml-sign-file/`)
    .then((response) => response.data);
}

export function getApprovalHistory(
  id: string
): Promise<APIResponse<Approver[]>> {
  return instance()
    .post(`/contractDraft/ApprovalHistory`, { id })
    .then((response) => response.data);
}

export function acceptContract(
  contractDraftId: string
): Promise<APIResponse<boolean>> {
  return instance()
    .post(`/contractDraft/is-contract/accepted`, { contractDraftId })
    .then((response) => response.data);
}
