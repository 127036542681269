import React, {
  forwardRef,
  memo,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  actTableLabels,
  currencyFormat,
  dateFormat,
  joinValues,
  NIGHT_SHIFT_ID,
  roundToTwo,
} from "@app/helpers";
import {
  IconChevronDown24,
  IconChevronUp24,
  IconClose24,
  IconDay24,
  IconNight24,
  IconPencil24,
  IconPlay24,
  IconPlus24,
  IconSuccessCircle24,
  IconTrash24,
} from "@app/icons";
import {
  BaseButton,
  Button,
  Grid,
  Select,
  SelectOption,
  Table,
  TextField,
} from "@app/components";
import {
  ActDetail,
  ActVehicleItemV2,
  CompanyType,
  FileObject,
  KIPVehicleDetails,
  Task,
  VehicleJournalForActV2,
} from "@app/models";
import {
  getCompanyProjectSites,
  getConstructiveByCompany,
  getVehiclesByActIdV2,
} from "@app/api";
import styled from "styled-components";
import { theme } from "styled-tools";
import { useNotification } from "@app/providers";
import {
  ConstructiveContext,
  ConstructiveReq,
  CustomConstructive,
  CustomProjectSite,
} from "@app/common";
import Alert from "../../../pages/KIP/components/Alert";
import { ModalKIPVehicleTrack } from "@app/modals";
import TableRowFile from "../../../common/TableRowFile";
import { downloadAllFiles } from "../../../common/TableRowFile/TableRowFile";

interface Props {
  taskData: Task | null;
  actData: ActDetail | null;
  onApprove: () => void;
  onConstructiveAdded: () => void;
  onConstructiveRemoved: () => void;
}

const ParentCell = styled.td`
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding: 12px;
  width: 1%;
  white-space: nowrap;
  text-align: left;
  border-top: 1px solid ${theme("color.grayLight")};
  font-family: ${theme("fontFamily")};
  color: ${theme("color.dark")};
  background-color: ${theme("color.blueLight")};
`;

const WrappedCell = styled.div`
  max-width: 340px;
  font-size: 14px;
  line-height: 16px;
  white-space: normal;
`;

const StyledButton = styled.button`
  border: none;
  background: transparent;
  outline: none;
  cursor: pointer;
  color: ${theme("color.orange")};
  opacity: 0.8;

  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }

  &:disabled {
    opacity: 1 !important;
    transform: scale(1);
    cursor: default;
    color: ${theme("color.gray")};
    box-shadow: none;
  }
`;

const CustomCell = styled.div<{ width: number }>`
  white-space: break-spaces;
  width: ${(props) => `${props.width}px`};
`;

const SpanWithHover = styled.span`
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const StyledConstructive = styled.div`
  width: 1024px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  margin: 24px 0;
  border: 1px #acacac solid;

  h4 {
    margin: 0;
  }
`;

const StylePlayBtn = styled.button`
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: ${theme("color.green")};
  cursor: pointer;
  border-radius: 50%;
`;

const TaskAOS = forwardRef((props: Props, ref) => {
  const { showNotification } = useNotification();
  const { taskData, onApprove, onConstructiveAdded, onConstructiveRemoved } =
    props;
  const localRef = useRef<null | HTMLDivElement>(null);
  const [actVehicles, setActVehicles] = useState<VehicleJournalForActV2[]>([]);
  const [collapsedRows, setCollapsedRows] = useState<number[]>([]);
  const [constructiveList, setConstructiveList] = useState<
    SelectOption<CustomConstructive>[]
  >([]);
  const { requestList, setRequestList } = useContext(ConstructiveContext);
  const [requestRow, setRequestRow] = useState<ConstructiveReq>();
  const [requestRowIndex, setRequestRowIndex] = useState(-1);
  const [selectedAct, setSelectedAct] = useState<ActVehicleItemV2>();
  const [vehicleDetails, setVehicleDetails] =
    useState<KIPVehicleDetails | null>(null);
  const [totalConstructiveSum, setTotalConstructiveSum] = useState(0);
  const [fileLoading, setFileLoading] = useState(false);

  const getTableData = useCallback(async (actId: string) => {
    const response = await getVehiclesByActIdV2(actId);
    setActVehicles(response.data);
  }, []);

  const getActConstructives = useCallback(
    async (customer: CompanyType | null) => {
      if (customer) {
        const response = await getConstructiveByCompany(customer.id);
        setConstructiveList(
          response.data.map((item) => ({
            value: item.id,
            label: item.name || "-",
          }))
        );
      }
    },
    []
  );

  const getProjectSites = useCallback(
    async (params: any) => {
      if (taskData?.customer && taskData?.project) {
        const { customer, project } = taskData;
        const result = await getCompanyProjectSites({
          ...params,
          customerId: customer.id,
          projectId: project.id,
        });

        let data: CustomProjectSite[] = [];

        if (result.data) {
          data = result.data.map((item) => ({
            id: item!.projectSite.id,
            name: item?.projectSite.name || "—",
            isNewProjectSite: true,
          }));
        }
        return { data } as any;
      }
      return new Promise<any>(() => {});
    },
    [taskData]
  );

  useEffect(() => {
    if (!taskData) return;
    getTableData(taskData.sourceId).then(() => {});
    getActConstructives(taskData.customer).then(() => {});
  }, [taskData, getTableData, getActConstructives]);

  useEffect(() => {
    // Calculate the total constructive sum
    const total = requestList.reduce((sum, request) => {
      return sum + (request.sum || 0);
    }, 0);
    setTotalConstructiveSum(total);
  }, [requestList]);

  const onCloseTrackModal = useCallback(() => {
    setVehicleDetails(null);
  }, []);

  const onPlayClick = useCallback(
    (act: ActVehicleItemV2) => () => {
      setSelectedAct(act);
      setVehicleDetails({
        workStartDateTime: act.startTime,
        workEndDateTime: act.endTime,
      } as KIPVehicleDetails);
    },
    []
  );

  const onFilesDownload = useCallback(
    (files: FileObject[]) => async () => {
      try {
        setFileLoading(true);
        await downloadAllFiles(files);
        setFileLoading(false);
      } catch (e) {
        setFileLoading(false);
        showNotification({
          message: "Не удалось скачать все файлы",
          variant: "error",
        });
      }
    },
    [showNotification]
  );

  const getMapTableData = useCallback(
    (array: ActVehicleItemV2[]) => {
      return array.map((item, index) => {
        if (!item) {
          return [];
        }

        return [
          <StylePlayBtn onClick={onPlayClick(item)}>
            <IconPlay24 />
          </StylePlayBtn>,
          `${index + 1}`,
          `${dateFormat(item.workDate, "dd.MM.yyyy")}`,
          item.shiftTypeDto?.id === NIGHT_SHIFT_ID ? (
            <IconNight24 />
          ) : (
            <IconDay24 />
          ),
          joinValues([
            dateFormat(item.startTime, "HH:mm"),
            dateFormat(item.endTime, "HH:mm"),
          ]),
          <WrappedCell>
            {joinValues([item.vehicleTypeDto, item.nomenclature])}
          </WrappedCell>,
          item.characteristic?.name,
          item.serviceType?.name,
          item.govNumber,
          item.workPerformed,
          item.hoursGps || 0,
          item.shiftDuration,
          item.unitDto?.name,
          currencyFormat(item.price),
          item.toPay,
          currencyFormat(roundToTwo(item.summa)),
          currencyFormat(roundToTwo(item.summaNds)),
          currencyFormat(roundToTwo(item.summa)), // total
          item.comment,
          <TableRowFile files={item.files} />,
        ];
      });
    },
    [onPlayClick]
  );

  const reduceTableData = useCallback(
    (item: VehicleJournalForActV2) => {
      if (item && item.items) {
        return getMapTableData(item.items);
      }

      return [];
    },
    [getMapTableData]
  );

  const mapTableCollapsedData = useCallback((item: VehicleJournalForActV2) => {
    if (!item) {
      return [];
    }

    return [
      <ParentCell>
        <IconChevronDown24 />
      </ParentCell>,
      <ParentCell colSpan={3} style={{ minWidth: "170px" }}>
        Итого по ед. изм.
      </ParentCell>,
      <ParentCell />,
      <ParentCell>{item.vehicleTypeName}</ParentCell>,
      <ParentCell>{item.characteristic?.name}</ParentCell>,
      <ParentCell />,
      <ParentCell>{item.govNumber}</ParentCell>,
      <ParentCell>{item.totalWorkPerformed}</ParentCell>,
      <ParentCell>{item.totalHoursGps}</ParentCell>,
      <ParentCell>{item.totalShiftDuration}</ParentCell>,
      <ParentCell>{item.unitDto?.name}</ParentCell>,
      <ParentCell />,
      <ParentCell>{currencyFormat(item.totalToPay)}</ParentCell>,
      <ParentCell>{currencyFormat(item.totalSumma)}</ParentCell>,
      <ParentCell>{currencyFormat(item.totalSummaNds)}</ParentCell>,
      <ParentCell>{currencyFormat(item.totalSumma)}</ParentCell>,
      <ParentCell />,
      <ParentCell />,
    ];
  }, []);

  const tableData = useMemo<(string | ReactNode)[][]>(
    () => actVehicles.map(mapTableCollapsedData),
    [actVehicles, mapTableCollapsedData]
  );

  const tableDataChildren = useMemo<(string | ReactNode)[][][]>(
    () => actVehicles.map(reduceTableData),
    [actVehicles, reduceTableData]
  );

  const reduceItemsSum = useCallback(
    (vehicleItems: ActVehicleItemV2[] | null, key: keyof ActVehicleItemV2) => {
      if (!vehicleItems) return 0;

      const reduced = vehicleItems.reduce((sum, item) => {
        return sum + ((item[key] || 0) as number);
      }, 0);
      return Number(reduced.toFixed(2));
    },
    []
  );

  const reduceSum = useCallback(
    (key: keyof ActVehicleItemV2, onlyValue = false) => {
      const summa = actVehicles.reduce((acc, cur) => {
        return acc + reduceItemsSum(cur.items, key);
      }, 0);
      return onlyValue ? Number(summa.toFixed(2)) : currencyFormat(summa);
    },
    [actVehicles, reduceItemsSum]
  );

  const onParentRowExpand = useCallback(() => {
    if (collapsedRows.length) {
      setCollapsedRows([]);
    } else {
      setCollapsedRows(tableData.map((_, i) => i));
    }
  }, [collapsedRows.length, tableData]);

  const tableExtraRow = useMemo<(string | ReactNode)[]>(
    () =>
      actTableLabels.map((_, index) => {
        if (index === 0) {
          return (
            <StyledButton onClick={onParentRowExpand}>
              {collapsedRows.length ? (
                <IconChevronDown24 />
              ) : (
                <IconChevronUp24 />
              )}
            </StyledButton>
          );
        } else if (index === 1) {
          return "Итого";
        } else if (index === 15) {
          return reduceSum("summa");
        } else if (index === 16) {
          return reduceSum("summaNds");
        } else if (index === 17) {
          return reduceSum("summa"); // total
        } else if (index === 19) {
          const itemsMap = actVehicles.flatMap((item) => item.items);
          const collectFiles = itemsMap.flatMap((item) => item?.files ?? []);
          return collectFiles.length ? (
            <Button
              variant={"contained"}
              text={"Скачать все"}
              size={"small"}
              showLoader={fileLoading}
              onClick={onFilesDownload(collectFiles)}
            />
          ) : null;
        }
        return "";
      }),
    [
      actVehicles,
      collapsedRows.length,
      fileLoading,
      onFilesDownload,
      onParentRowExpand,
      reduceSum,
    ]
  );

  useEffect(() => {
    setTimeout(() => {
      localRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
  }, [requestList]);

  // constructives
  const onRowUpdate = useCallback(
    (index: number, value?: any, name?: keyof ConstructiveReq) => {
      // при добавлении строки index равен -1
      if (index >= 0 && name && requestRow) {
        setRequestRow((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setRequestRowIndex(requestList.length);
        const newRequestObj = {
          constructive: undefined,
          projectSite: undefined,
          sum: 0,
        };
        setRequestRow(newRequestObj);
        requestList.push(newRequestObj);
        setRequestList([...requestList]);
      }
    },
    [requestList, requestRow, setRequestList]
  );

  const onSelectChange = useCallback(
    (index: number) => (value: any, name: keyof ConstructiveReq | undefined) =>
      onRowUpdate(index, name === "sum" ? Number(value) : value, name),
    [onRowUpdate]
  );

  const onProjectSiteFieldUpdate = useCallback(
    (index: number, value: string | CustomProjectSite | null) => {
      // компонент выдает string при добавлении новой строки
      const isStringValue = typeof value === "string";
      let obj: SelectOption<CustomProjectSite> | undefined = undefined;
      if (isStringValue) {
        obj = {
          value: 0,
          label: value,
          item: {
            id: "custom",
            name: value,
            isNewProjectSite: true,
          },
        };
      }
      // добавить новое Пятно
      onRowUpdate(index, obj || value, "projectSite");
    },
    [onRowUpdate]
  );

  const constructivesTableLabels = useMemo<(string | ReactNode)[]>(
    () => ["№", "Конструктив", "Участок/Пятно", "Сумма", "", ""],
    []
  );

  const onConstructivesSave = useCallback(
    (index: number) => () => {
      if (requestRow) {
        if (!requestRow.sum || !requestRow.constructive) {
          showNotification({
            message: "Заполните все поля",
            variant: "warning",
          });
          return;
        }
        requestList[index] = requestRow;
        setRequestList([...requestList]);
        // reset
        setRequestRowIndex(-1);
        setRequestRow(undefined);
        onConstructiveAdded(); // Уведомить о добавлении конструктивов
      }
    },
    [
      requestList,
      requestRow,
      setRequestList,
      showNotification,
      onConstructiveAdded,
    ]
  );

  const onDeleteConstructive = useCallback(
    (index: number) => {
      requestList.splice(index, 1);
      setRequestList([...requestList]);
      if (requestList.length === 0) {
        onConstructiveRemoved(); // Уведомить о удалении всех конструктивов
      }
    },
    [onConstructiveRemoved, requestList, setRequestList]
  );

  const onCancelConstructiveEdit = useCallback(() => {
    setRequestRowIndex(-1);
    setRequestRow(undefined);
  }, []);

  const showValueCells = useCallback(
    (value: ConstructiveReq, index: number) => {
      return [
        `${index + 1}.`,
        <CustomCell width={320}>
          <p>{value.constructive?.label}</p>
        </CustomCell>,
        <CustomCell width={320}>
          <p>{value.projectSite?.label || "—"}</p>
        </CustomCell>,
        <CustomCell width={180}>
          <p>{currencyFormat(value.sum || 0)}</p>
        </CustomCell>,
        <BaseButton
          onClick={() => {
            setRequestRow(value);
            setRequestRowIndex(index);
          }}
        >
          <SpanWithHover>
            <IconPencil24 />
          </SpanWithHover>
        </BaseButton>,
        <BaseButton
          onClick={() => {
            onDeleteConstructive(index);
          }}
        >
          <SpanWithHover>
            <IconTrash24 />
          </SpanWithHover>
        </BaseButton>,
      ];
    },
    [onDeleteConstructive]
  );

  const editValueCells = useCallback(
    (index: number) => {
      return [
        `${index + 1}.`,
        <CustomCell width={320}>
          <Select
            name="constructive"
            options={constructiveList}
            value={requestRow?.constructive}
            valueKey="id"
            labelKey="name"
            onChange={onSelectChange(index)}
          />
        </CustomCell>,
        <CustomCell width={320}>
          <Select
            name="projectSite"
            loadData={getProjectSites}
            value={requestRow?.projectSite}
            valueKey="id"
            labelKey="name"
            onInputChange={(value) => onProjectSiteFieldUpdate(index, value)}
            onChange={onSelectChange(index)}
          />
        </CustomCell>,
        <CustomCell width={180}>
          <TextField
            name="sum"
            value={`${requestRow?.sum}`}
            type="number"
            onChange={onSelectChange(index)}
          />
        </CustomCell>,
        <BaseButton onClick={onConstructivesSave(index)}>
          <span title="Сохранить изменения">
            <IconSuccessCircle24 />
          </span>
        </BaseButton>,
        <BaseButton onClick={onCancelConstructiveEdit}>
          <span title="Отменить редактирование">
            <IconClose24 />
          </span>
        </BaseButton>,
      ];
    },
    [
      constructiveList,
      getProjectSites,
      onCancelConstructiveEdit,
      onConstructivesSave,
      onProjectSiteFieldUpdate,
      onSelectChange,
      requestRow?.constructive,
      requestRow?.projectSite,
      requestRow?.sum,
    ]
  );

  const constructivesTableData = useMemo(
    () =>
      requestList.map((item, index) => {
        if (requestRowIndex === index) {
          return editValueCells(index);
        }
        return showValueCells(item, index);
      }),
    [editValueCells, requestList, requestRowIndex, showValueCells]
  );

  const constructiveTableExtraRow = useMemo<
    (string | ReactNode)[] | undefined
  >(() => {
    if (requestList.length === 0) {
      return;
    }
    return constructivesTableLabels.map((_, index) => {
      if (index === 1) {
        return "Итого:";
      }
      if (index === 3) {
        const sum = requestList.reduce((acc, cur) => acc + (cur.sum || 0), 0);
        return (
          <CustomCell width={180}>
            <p>{currencyFormat(sum || 0)}</p>
          </CustomCell>
        );
      }
      return "";
    });
  }, [constructivesTableLabels, requestList]);

  const handleApprove = useCallback(() => {
    const totalSum = reduceSum("summa", true);
    if (totalSum !== totalConstructiveSum) {
      console.log(
        `Сумма распределения по конструктивам: ${totalConstructiveSum}. Сумма табеля: ${totalSum}`
      );
      showNotification({
        message: `Сумма распределения по конструктивам не равна сумме табеля`,
        variant: "error",
      });
      return;
    }
    onApprove();
  }, [reduceSum, totalConstructiveSum, onApprove, showNotification]);

  // Экспортируем handleApprove через ref
  useImperativeHandle(ref, () => ({
    handleApprove,
  }));

  return (
    <div>
      <Table
        labels={actTableLabels}
        collapsedData={tableData}
        nodeData={tableDataChildren}
        extraRow={tableExtraRow}
        collapsedRows={collapsedRows}
        disableOverflow
        onClick={() => {}}
        onRowCollapse={(val) => setCollapsedRows(val)}
      />
      {!!constructiveList.length && (
        <StyledConstructive ref={localRef}>
          <h4>Распределение по конструктивам</h4>
          <Grid columns={2}>
            <Table
              labels={constructivesTableLabels}
              data={constructivesTableData}
              disableOverflow={true}
              extraRow={constructiveTableExtraRow}
            />
          </Grid>
          {requestList.some((req) => !req.constructive && !req.sum) && (
            <Grid>
              <Alert
                text={"Для добавления конструктива - нажмите галочку"}
                animated={false}
                onClick={() => {}}
              />
            </Grid>
          )}
          <Grid>
            <Button
              startIcon={IconPlus24}
              disabled={!!requestRow}
              onClick={() => onRowUpdate(-1)}
            />
          </Grid>
        </StyledConstructive>
      )}
      {selectedAct && (
        <ModalKIPVehicleTrack
          open={!!vehicleDetails}
          projectId={taskData?.project.id}
          vehicleDetails={vehicleDetails}
          vehicleId={selectedAct.vehicleId}
          vehicleGovNumber={selectedAct.govNumber}
          onClose={onCloseTrackModal}
        />
      )}
    </div>
  );
});

export default memo(TaskAOS);
