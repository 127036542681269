import { LayoutLanding } from "@app/layouts";
import { Container } from "@app/components";
import { useParams } from "react-router-dom";
import { useMemo } from "react";
import styled from "styled-components";
import { theme } from "styled-tools";

const contacts = [
  {
    title: "Call-центр доверия",
    name: "",
    phones: ["+7(771)046-05-33"],
    email: "support@dispatchcds.com",
  },
  {
    title: "Директор ЦДС DISPATCH",
    name: "НАСИМУЛДИНОВ НҰРХАТ ЕРКЕЖАНҰЛЫ",
    phones: ["+7(777)110-75-44"],
    email: "nn@dispatchcds.com",
  },
  {
    title: "Руководитель ЦДС DISPATCH",
    name: "ШОЛПАНҚҰЛ БЕКБОЛАТ САЙРАМБАЙҰЛЫ",
    phones: ["+7(776)122-20-51"],
    email: "sbs@dispatchcds.com",
  },
  {
    title: "Директор Службы Экономической Безопасности Холдинга",
    name: "ЖОЛДАНГАРОВ ЕРМЕК ТАЛГАТОВИЧ",
    phones: ["+7(701)513-00-27", "+7(777)110-33-66"],
    email: "e_zholdangarov@bi.group",
  },
  {
    title: "Руководитель отдела Службы Экономической Безопасности по г. Алматы",
    name: "МУРЗАГАЛИЕВ ЖАЛГАС ГАРИПОВИЧ",
    phones: ["+7(777)110-78-68"],
    email: "murzagaliyev_zh@bi.group",
    region: "almaty",
  },
  {
    title: "Специалист Службы Экономической Безопасности по г. Шымкент",
    name: "МЫНБАЕВ АСКАР СЕРИКТОЛЕУОВИЧ",
    phones: ["+7(705)749-94-52"],
    email: "mynbaev_as@bi.group",
    region: "shymkent",
  },
];

const StyledTextLink = styled.a`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  text-decoration: unset;
  color: ${theme("color.dark")};

  &:hover {
    text-decoration: underline;
  }
`;

const PageBusinessEthicMemo = () => {
  const params = useParams();

  const filteredContactList = useMemo(
    () =>
      contacts.filter((item) => {
        return !item.region || params.region === item.region;
      }),
    [params.region]
  );

  return (
    <LayoutLanding>
      <Container>
        <h1>Памятка о соблюдении этики при ведении деловых отношений</h1>
        <p>
          Напоминаем Вам о ведении честных и прозрачных сделок, обоснованных
          конкурентным распределением объемов транспортных услуг на строительных
          участках.
        </p>
        <br />
        <p>
          Если вы стали свидетелем вымогательства, склонения к даче взятки или
          других коррупционных действий со стороны представителей Заказчика (ИТР
          проекта, а также другие службы заказчика) или со стороны ЦДС
          (Dispatch) связанных с недобросовестным распределением объемом работ
          при привлечении и эксплуатации техники на проектах Заказчика - просим
          придать огласку происшествию и обратиться к ответственным лицам ЦДС
          (Dispatch) или к сотрудникам Службы Экономической Безопасности
          Холдинга BI Group.
        </p>
        <br />
        <p>
          Заявляем, что Холдинг BI Group и Команда ЦДС (Dispatch) придерживаются
          политики прозрачного выбора партнера, прозрачного учета работы техники
          и относится к коррупционным действиям с нулевой толерантностью. Во
          избежание вышеперечисленных случаев и улучшения работы сервиса просим
          сообщать о всех прямых или косвенных факторах коррупционных действий
          сотрудниками.
        </p>
        <br />
        <br />
        {filteredContactList.map((item, index) => (
          <div key={index}>
            <p>
              <b>{item.title}</b>
            </p>
            <p>{item.name}</p>
            <p>
              тел:
              {item.phones.map((phone, phoneIndex) => (
                <StyledTextLink key={phone} href={`tel:${phone}`}>
                  {phoneIndex > 0 ? ", " : " "}
                  {phone}
                </StyledTextLink>
              ))}
            </p>
            <p>
              почта:{" "}
              <StyledTextLink href={`mailto:${item.email}`}>
                {item.email}
              </StyledTextLink>
            </p>
            <br />
          </div>
        ))}
      </Container>
    </LayoutLanding>
  );
};

export default PageBusinessEthicMemo;
