import React, {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Dialog,
  Modal,
  ModalProps,
  SelectOption,
  Stepper,
  useForm,
} from "@app/components";
import styled from "styled-components";
import { StepView1, StepView2, StepView3 } from "./components";
import { useNotification, useUser } from "@app/providers";
import * as yup from "yup";
import {
  APIResponse,
  confirmCompanyStatus,
  deleteFile,
  getCompanyById,
  getCompanyFileTypes,
  joinAnCompany,
  onAddBankAccount,
  onAddCompanyFile,
  onCreateAddress,
  onCreateCompany,
  onUpdateAddress,
  onUpdateBankAccount,
  onUpdateCompany,
} from "@app/api";
import { format } from "date-fns";
import CompanyStatusChip from "../../common/CompanyStatusChip/CompanyStatusChip";
import UpdateDialog from "components/UpdateDialog";
import { IconTrash24 } from "@app/icons";
import {
  formatPhoneNumber,
  getAxiosErrorMessage,
  stripPhoneNumberFormatting,
} from "@app/helpers";
import { AxiosError } from "axios";

interface Props extends Omit<ModalProps, "title"> {
  data: any | null;
  onClose: () => void;
}

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const StyledContent = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 24px 16px;
  padding: 16px 0;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
`;

const StyledStepper = styled.div`
  flex-grow: 1;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 16px;
`;
const StyledButtonBack = styled.div`
  display: flex;
`;

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid #d8d8d8;
  justify-content: space-between;
  padding: 16px 16px 48px 16px;
  box-sizing: border-box;
`;

const StyledModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledModalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 24px;
  color: #1c2246;
`;

const StyledChip = styled.div`
  margin-left: 10px;
`;

function ModalOrganizationCreate(props: Props) {
  const { onClose, open, data, ...restProps } = props;
  const { user } = useUser();
  const { showNotification } = useNotification();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFilesAdded, setIsFilesAdded] = useState(false);
  const [isSavingBankAccount, setIsSavingBankAccount] = useState(false);
  const [isConfirmingStatus, setIsConfirmingStatus] = useState(false);
  const [companyId, setCompanyId] = useState<string>("");
  const [regionIdUpdate, setRegionIdUpdate] = useState<string>("");
  const [addressIdUpdate, setAddressIdUpdate] = useState<string>("");
  const [statusId, setStatusId] = useState<number | null>(null);
  const [companyFileTypes, setCompanyFileTypes] = useState<SelectOption<any>[]>(
    []
  );
  const [files, setFiles] = useState<{ [key: string]: any[] }>({});
  const [isUpdateComplete, setIsUpdateComplete] = useState(false);
  const [isBankAccountSaved, setIsBankAccountSaved] = useState(false);
  const [validationSchema, setValidationSchema] = useState<any>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isFilesSaved, setIsFilesSaved] = useState<boolean>(false);
  const [isStep2Saved, setIsStep2Saved] = useState<boolean>(false);
  const {
    pending,
    onChange,
    values: formData,
    errors,
    validate,
    setPending,
    resetForm,
    setValues,
  } = useForm<any>({
    values: {
      organization: "",
      companyType: null,
      type: null,
      name: user,
      bin: "",
      nds: null,
      powerOfAttorniesNumber: "",
      powerOfAttorniesDate: null,
      registrationCertificateNumber: "",
      registrationCertificateSeries: "",
      registrationCertificateDate: null,
      region: null,
      street: "",
      block: "",
      apartment: "",
      headName: "",
      headIdn: "",
      registrationNumber: "",
      email: "",
      phone: "",
      kbe: "",
      country: null,
    },
    schema: validationSchema,
  });
  const [filesToDelete, setFilesToDelete] = useState<any>([]);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  useEffect(() => {
    if (props.data && props.data.companyStatus === 2) {
      setShowUpdateDialog(true);
    }
  }, [props.data]);

  const onConfirmToEdit = useCallback(() => {
    setShowUpdateDialog(false);
  }, []);

  useEffect(() => {
    const typeValue = formData.type?.value;
    const isKZ = formData.country?.label === "Казахстан";
    setValidationSchema(
      yup.object().shape({
        organization: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        companyType: yup
          .object()
          .nullable()
          .required("Поле обязательно к заполнению"),
        type: yup.object().nullable().required("Поле обязательно к заполнению"),
        nds: yup.object().nullable().required("Поле обязательно к заполнению"),
        bin: yup
          .string()
          .nullable()
          .matches(/^\d{12}$/, "ИИН/БИН должен содержать ровно 12 цифр")
          .required("Поле обязательно к заполнению"),
        headName: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        headIdn: yup
          .string()
          .nullable()
          .matches(/^\d{12}$/, "ИИН/БИН должен содержать ровно 12 цифр")
          .required("Поле обязательно к заполнению"),
        registrationNumber: !isKZ
          ? yup.string().nullable().required("Поле обязательно к заполнению")
          : yup.string().nullable().notRequired(),
        registrationCertificateNumber:
          typeValue === 3
            ? yup.string().nullable().required("Поле обязательно к заполнению")
            : yup.string().nullable().notRequired(),
        registrationCertificateSeries:
          typeValue === 3
            ? yup.string().nullable().notRequired()
            : yup.string().nullable().notRequired(),
        registrationCertificateDate:
          typeValue === 3
            ? yup.date().required("Поле обязательно к заполнению")
            : yup.date().nullable().notRequired(),
        powerOfAttorniesNumber:
          typeValue === 2
            ? yup.string().nullable().notRequired()
            : yup.string().nullable().notRequired(),
        powerOfAttorniesDate:
          typeValue === 2
            ? yup.date().required("Поле обязательно к заполнению")
            : yup.date().nullable().notRequired(),
        region: yup
          .object()
          .nullable()
          .required("Поле обязательно к заполнению"),
        street: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        block: yup
          .string()
          .nullable()
          .required("Поле обязательно к заполнению"),
        phone: isKZ
          ? yup
              .string()
              .nullable()
              .required("Поле обязательно к заполнению")
              .matches(
                /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
                "Номер телефона должен быть в формате +7 (###) ###-##-##"
              )
          : yup.string().nullable().required("Поле обязательно к заполнению"),
        kbe: yup.string().nullable().required("Поле обязательно к заполнению"),
        email: yup
          .string()
          .nullable()
          .email("Введите корректный адрес электронной почты")
          .required("Поле обязательно к заполнению"),
      })
    );
  }, [formData]);

  useEffect(() => {
    const fetchData = async () => {
      if (data && data.id) {
        try {
          const response = await getCompanyById(data?.id);
          if (response && response.succeeded) {
            const companyData = response.data;
            const firstAddress = response.data?.addresses?.[0] || {};
            setCompanyId(response.data.id);
            setRegionIdUpdate(firstAddress?.region && firstAddress.region?.id);
            setAddressIdUpdate(firstAddress?.id);
            const currentUserData = response.data.users.find(
              (u: any) => u.user?.id === user?.id
            );

            if (!currentUserData) {
              return;
            }

            const registrationDate =
              currentUserData.registrationCertificate &&
              currentUserData.registrationCertificate?.date;
            const powerOfAttorneyDate =
              currentUserData.powerOfAttorney &&
              // @ts-ignore
              currentUserData.powerOfAttorney?.date;

            if (companyData && companyData.files) {
              const initialFiles: any = {};
              companyData.files.forEach((fileData: any) => {
                const typeId = fileData.typeId;
                if (!initialFiles[typeId]) {
                  initialFiles[typeId] = [];
                }
                initialFiles[typeId].push({
                  name: fileData.file.name,
                  url: fileData.file.path,
                  id: fileData.file.id,
                });
              });

              setFiles(initialFiles);
            }
            if (companyData && companyData.bankAccounts) {
              const bankAccounts = companyData.bankAccounts.map(
                (account: any) => ({
                  bank: {
                    value: account.bank && account.bank.id,
                    label: account.bank && account.bank.name,
                    item: account.bank,
                  },
                  accountNumber: account.accountNumber,
                  isMain: account.isMain,
                  bankAccountId: account.id,
                })
              );
              setValues({
                ...formData,
                bank: bankAccounts,
              });
            }

            setValues({
              organization: companyData.name || "",
              bin: companyData.bin || "",
              email: companyData.email || "",
              phone: companyData.phone
                ? formatPhoneNumber(companyData.phone, formData.country?.label)
                : "",
              registrationNumber: companyData.registrationNumber || "",
              headIdn: companyData.headIdn || "",
              headName: companyData.headName || "",
              kbe: companyData.kbe || "",

              country: companyData.country
                ? {
                    value: companyData.country.id,
                    label: companyData.country.name,
                    item: companyData.country,
                  }
                : null,
              nds: companyData.nds
                ? {
                    value: companyData.nds.id,
                    label: companyData.nds.name,
                    item: companyData.nds,
                  }
                : null,
              companyType: companyData.type
                ? { value: companyData?.type.id, label: companyData?.type.name }
                : null,
              type: currentUserData.employmentType
                ? {
                    value: currentUserData?.employmentType.id,
                    label: currentUserData?.employmentType.name,
                    item: currentUserData?.employmentType,
                  }
                : null,
              region: firstAddress?.region
                ? {
                    values: firstAddress?.region?.id,
                    label: firstAddress?.region?.name,
                    item: firstAddress?.region,
                  }
                : null,
              street: firstAddress?.street || "",
              block: firstAddress?.block || "",
              apartment: firstAddress?.apartment || "",
              powerOfAttorniesNumber:
                // @ts-ignore
                currentUserData.powerOfAttorney?.number || "",
              powerOfAttorniesDate: new Date(powerOfAttorneyDate) || null,
              registrationCertificateNumber:
                currentUserData.registrationCertificate?.number || "",
              registrationCertificateSeries:
                currentUserData.registrationCertificate?.series || "",
              registrationCertificateDate: new Date(registrationDate) || null,
            });
          }
        } catch (error) {
          console.error("Ошибка при загрузке данных компании: ", error);
        }
      }
    };

    fetchData();
  }, [data, setValues, user]);

  const steps = useMemo(
    () => ["Общая информация", "Документы", "Банковские реквизиты"],
    []
  );

  const modalTitle = useMemo<ReactNode>(() => {
    return (
      <StyledModalHeader>
        <StyledModalTitle>
          {!data ? "Добавление организации" : `${data?.name}`}
          {data && (
            <StyledChip>
              <CompanyStatusChip
                status={data?.companyStatus}
                comment={data?.comment}
              />
            </StyledChip>
          )}
        </StyledModalTitle>
      </StyledModalHeader>
    );
  }, [data]);

  const loadCompanyFileTypes = useCallback(async () => {
    try {
      const response = await getCompanyFileTypes();
      let initialData = response.data.map((companyFileTypes: any) => ({
        value: companyFileTypes.id,
        label: companyFileTypes.name,
      }));

      const isCompanyType1 = formData.companyType?.value === 1;
      const registrationCertificateDate = formData.registrationCertificateDate
        ? new Date(formData.registrationCertificateDate)
        : null;
      const requiresNdsCertificate =
        formData.nds && formData.nds.item && formData.nds.item.exemptTurnover;

      let allowedTypesForConditions = [
        {
          condition: isCompanyType1 && formData.type?.value === 3,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение личности владельца",
            "Справка о наличии расчетного счета",
            "Уведомление о начале деятельности в качестве индивидуального предпринимателя",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
        {
          condition: isCompanyType1 && formData.type?.value === 2,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение доверенного лица",
            "Доверенность",
            "Справка о наличии расчетного счета",
            "Удостоверение личности владельца",
            "Уведомление о начале деятельности в качестве индивидуального предпринимателя",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
        {
          condition:
            formData.companyType?.value === 2 && formData.type?.value === 1,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение личности владельца",
            "Приказ",
            "Устав",
            "Справка о наличии расчетного счета",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
        {
          condition:
            formData.companyType?.value === 2 && formData.type?.value === 2,
          types: [
            "Свидетельство о гос.регистрации",
            "Удостоверение личности владельца",
            "Приказ",
            "Устав",
            "Доверенность",
            "Справка о наличии расчетного счета",
            "Удостоверение доверенного лица",
            "Дополнительные файлы по запросу заказчиков",
          ],
        },
      ];

      let filteredData = initialData.filter(({ label }: any) => {
        for (let condition of allowedTypesForConditions) {
          if (condition.condition && condition.types.includes(label)) {
            return true;
          }
        }
        if (label === "Свидетельство о постановке на НДС") {
          return !requiresNdsCertificate;
        }
        return false;
      });

      const registrationCertificateIndex = filteredData.findIndex(
        (fileType: any) => fileType.label === "Свидетельство о гос.регистрации"
      );

      const notificationIndex = initialData.findIndex(
        (fileType: any) =>
          fileType.label === "Уведомление о государственной регистрации ИП"
      );

      if (
        isCompanyType1 &&
        registrationCertificateDate &&
        registrationCertificateDate >= new Date("2014-12-31")
      ) {
        if (registrationCertificateIndex > -1 && notificationIndex > -1) {
          const [registrationCertificateFile] = filteredData.splice(
            registrationCertificateIndex,
            1
          );
          const [notificationFile] = initialData.splice(notificationIndex, 1);
          filteredData.unshift(notificationFile);
          filteredData.unshift(registrationCertificateFile);
        } else if (registrationCertificateIndex > -1) {
          const [registrationCertificateFile] = filteredData.splice(
            registrationCertificateIndex,
            1
          );
          filteredData.unshift(registrationCertificateFile);

          const [notificationFile] = initialData.splice(notificationIndex, 1);
          filteredData.splice(1, 0, notificationFile);
        }
      } else {
        if (registrationCertificateIndex > -1) {
          const [registrationCertificateFile] = filteredData.splice(
            registrationCertificateIndex,
            1
          );
          filteredData.unshift(registrationCertificateFile);
        }
      }

      setCompanyFileTypes(filteredData);
    } catch (error) {
      console.error("Ошибка при загрузке списка: ", error);
    }
  }, [formData]);

  useEffect(() => {
    (async () => {
      await loadCompanyFileTypes();
    })();
  }, [loadCompanyFileTypes]);

  const areFilesUploaded = () => {
    const optionalFileTypeId = "26043326-f5cb-454a-81a0-ddd479669431";

    return companyFileTypes.every((fileType) => {
      if (fileType.value === optionalFileTypeId) return true;
      return files[fileType.value] && files[fileType.value].length > 0;
    });
  };

  const onFileDelete = useCallback(
    (fileTypeId: string, fileIndex: number) => {
      setFiles((prevFiles) => {
        const updatedFiles = { ...prevFiles };
        const filesList = updatedFiles[fileTypeId];

        if (filesList && filesList.length > fileIndex) {
          const fileToDelete = filesList[fileIndex];
          setFilesToDelete({ file: fileToDelete, fileTypeId, fileIndex });
          setShowDialog(true);
        }

        return updatedFiles;
      });
      setIsStep2Saved(false);
      const input = document.getElementById(
        `file-input-${fileTypeId}`
      ) as HTMLInputElement;
      if (input) {
        input.value = "";
      }
    },
    [setFiles]
  );

  const onDeleteConfirm = async () => {
    if (filesToDelete.file.id) {
      if (!companyId) {
        console.error("Error: CompanyId is not set.");
        showNotification({
          message: "Не указана компания",
          variant: "error",
        });
        return;
      }

      try {
        await deleteFile({
          companyId: companyId,
          fileId: filesToDelete.file.id,
        });
        setIsFilesSaved(false);
      } catch (error) {
        console.error("Error deleting vehicle file: ", error);
        showNotification({
          message: "Ошибка при удалении файла.",
          variant: "error",
        });
      }
    }

    setFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      const fileType = filesToDelete.fileTypeId;
      if (
        updatedFiles[fileType] &&
        updatedFiles[fileType].length > filesToDelete.fileIndex
      ) {
        updatedFiles[fileType].splice(filesToDelete.fileIndex, 1);
        if (updatedFiles[fileType].length === 0) {
          delete updatedFiles[fileType];
        }
      }
      return updatedFiles;
    });

    setShowDialog(false);
    setFilesToDelete([]);
    setIsStep2Saved(false);
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [open]);

  const handleSaveFiles = async (companyId: string) => {
    try {
      const actualCompanyId = data && data.id ? data.id : companyId;

      for (const typeId of Object.keys(files)) {
        for (const file of files[typeId]) {
          if (!file.id || file.isNew) {
            const response = await onAddCompanyFile({
              CompanyId: actualCompanyId,
              File: file,
              TypeId: typeId,
            });

            if (response.succeeded) {
              const updatedFile = response.data.file;

              setFiles((prevFiles) => {
                const updatedFilesForType = prevFiles[typeId].map((f) =>
                  f.name === file.name ? { ...f, ...updatedFile } : f
                );
                return { ...prevFiles, [typeId]: updatedFilesForType };
              });
            }
          }
        }
      }

      setIsFilesAdded(true);
      setIsFilesSaved(true);
    } catch (error) {
      console.error("Ошибка при сохранении файлов: ", error);
      setIsFilesAdded(false);
    }
  };

  const onSaveClick = useCallback(
    async (isSaving = true) => {
      const isValid = await validate();

      if (!isValid) {
        return;
      }
      setPending(true);

      const phoneNumber = formData.phone
        ? stripPhoneNumberFormatting(formData.phone)
        : "";

      const formattedPhoneNumber =
        formData.country.label === "Казахстан"
          ? `7${phoneNumber.slice(1)}`
          : formData.country.label === "Узбекистан"
          ? `9${phoneNumber.slice(1)}`
          : phoneNumber;

      const body: any = {
        typeId: formData.companyType && formData.companyType.value,
        name: formData.organization,
        bin: formData.bin,
        headName: formData.headName,
        kbe: formData.kbe,
        headIdn: formData.headIdn,
        countryId: formData.country.value,
        registrationNumber: formData.registrationNumber,
        phone: formattedPhoneNumber,
        email: formData.email,
        ndsId: formData.nds && formData.nds.value,
        isSigner: true,
        employmentTypeId: formData.type && formData.type.value,
        registrationCertificateNumber: formData.registrationCertificateNumber,
        registrationCertificateSeries:
          formData.registrationCertificateSeries || "",
        registrationCertificateDate: formData.registrationCertificateDate
          ? format(
              formData.registrationCertificateDate,
              "yyyy-MM-dd'T'HH:mm:ss"
            )
          : "",
        powerOfAttorniesNumber: formData.powerOfAttorniesNumber || "",
        powerOfAttorniesDate: formData.powerOfAttorniesDate
          ? format(formData.powerOfAttorniesDate, "yyyy-MM-dd'T'HH:mm:ss")
          : "",
      };

      let response;
      let organizationId;
      if (isSaving) {
        if (companyId) {
          response = await onUpdateCompany({ ...body, id: companyId });
          if (response?.succeeded) {
            organizationId = companyId;
            setIsUpdateComplete(true);
            await joinAnCompany({
              companyId: organizationId,
              isSigner: true,
              employmentTypeId: formData.type && formData.type.value,
              registrationCertificateNumber:
                formData.registrationCertificateNumber,
              registrationCertificateSeries:
                formData.registrationCertificateSeries || "",
              registrationCertificateDate: formData.registrationCertificateDate
                ? format(
                    formData.registrationCertificateDate,
                    "yyyy-MM-dd'T'HH:mm:ss"
                  )
                : "",
              powerOfAttorniesNumber: formData.powerOfAttorniesNumber || "",
              powerOfAttorniesDate: formData.powerOfAttorniesDate
                ? format(formData.powerOfAttorniesDate, "yyyy-MM-dd'T'HH:mm:ss")
                : "",
              fileId: formData.fileId,
            });
          }
        } else {
          try {
            response = await onCreateCompany(body);
            if (response?.succeeded) {
              organizationId = response.data.id;
              setCompanyId(organizationId);
              setIsUpdateComplete(false);
            } else {
              throw new Error(
                response.message || "Error while creating the company."
              );
            }
          } catch (error) {
            console.error("Error during company creation: ", error);
            showNotification({
              message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
              variant: "error",
            });
            setIsSuccessful(false);
            setPending(false);
          }
        }

        if (response?.succeeded) {
          setIsSuccessful(true);
          const statusId = response.data.status;
          setStatusId(statusId);

          if (companyId) {
            await onUpdateAddress({
              companyId: companyId,
              addressId: addressIdUpdate,
              regionId: regionIdUpdate,
              street: formData.street,
              block: formData.block,
              apartment: formData.apartment,
              isLegalAddress: true,
            });
          } else {
            const addressResponse = await onCreateAddress({
              companyId: organizationId,
              regionId: formData.region.value,
              street: formData.street,
              block: formData.block,
              apartment: formData.apartment,
              isLegalAddress: true,
            });
            if (addressResponse?.succeeded) {
              setRegionIdUpdate(addressResponse.data.regionId);
              setAddressIdUpdate(addressResponse.data?.id);
            }
          }

          showNotification({
            message: data
              ? "Общая информация отредактировано"
              : "Общая информация сохранено",
            variant: "success",
          });
        } else {
          showNotification({
            message: response.message,
            variant: "error",
          });
          setIsSuccessful(false);
        }
      }
      setPending(false);
    },
    [
      addressIdUpdate,
      companyId,
      data,
      formData,
      regionIdUpdate,
      setPending,
      showNotification,
      validate,
      onUpdateCompany,
      onCreateCompany,
      onUpdateAddress,
      onCreateAddress,
    ]
  );

  const onSaveDocuments = useCallback(async () => {
    setPending(true);

    await handleSaveFiles(companyId);

    setPending(false);
    showNotification({
      message: "Документы сохранены",
      variant: "success",
    });

    setIsStep2Saved(true);
  }, [companyId, handleSaveFiles, setPending, showNotification]);

  const onSaveBankAccount = useCallback(async () => {
    setIsSavingBankAccount(true);
    try {
      if (Array.isArray(formData.bank)) {
        const responses = await Promise.all(
          formData.bank.map(async (bankItem: any) => {
            const body = {
              companyId: !!data ? data.id : companyId,
              bankId: bankItem.bank.value,
              accountNumber: bankItem.accountNumber,
              isMain: bankItem.isMain,
            };

            if (!!data && !!bankItem.bankAccountId) {
              return onUpdateBankAccount({
                ...body,
                bankAccountId: bankItem.bankAccountId,
              });
            } else {
              return onAddBankAccount(body);
            }
          })
        );
        const succeeded = responses.every((response) => response?.succeeded);
        if (succeeded) {
          showNotification({
            message: "Банковские реквизиты сохранены",
            variant: "success",
          });
          setIsBankAccountSaved(true);
        } else {
          showNotification({
            message: "Ошибка при сохранении банковских реквизитов",
            variant: "error",
          });
        }
      }
    } catch (error) {
      console.error("Ошибка при сохранении банковских реквизитов: ", error);
      setIsSavingBankAccount(false);
      showNotification({
        message: getAxiosErrorMessage(error as AxiosError<APIResponse>),
        variant: "error",
      });
    } finally {
      setIsSavingBankAccount(false);
    }
  }, [companyId, data, formData.bank, showNotification]);

  const onConfirmStatus = useCallback(async () => {
    setIsConfirmingStatus(true);

    try {
      const body = {
        companyId: !!data ? data && data.id : companyId,
        statusId: 1,
      };
      const response = await confirmCompanyStatus(body);

      if (response?.succeeded) {
        showNotification({
          message: "Компания отправлена на проверку",
          variant: "success",
        });
        setIsConfirmingStatus(false);
      } else {
        showNotification({
          message:
            response.message || "Ошибка при подтверждении статуса компании",
          variant: "error",
        });
        setIsConfirmingStatus(false);
      }
      onClose();
      setCurrentStep(1);
      resetForm();
      window.location.reload();
    } catch (error) {
      console.error("Ошибка при подтверждении статуса компании: ", error);
      showNotification({
        message: "Ошибка при подтверждении статуса компании",
        variant: "error",
      });
      setIsConfirmingStatus(false);
    }

    setIsConfirmingStatus(false);
  }, [data, companyId, onClose, resetForm, showNotification]);

  const onPrevClick = useCallback(() => {
    if (currentStep <= 1) {
      return;
    }
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const onNextClick = useCallback(async () => {
    if (currentStep === 1) {
      setCurrentStep(2);
    } else if (currentStep === 3) {
      await onSaveClick(false);
    }
  }, [currentStep, onSaveClick]);

  const onNextStep = useCallback(async () => {
    if (currentStep === 2) {
      setCurrentStep(3);
    }
  }, [currentStep]);

  useEffect(() => {
    if (data) {
      setValues(data);
    } else {
      resetForm();
    }
  }, [data]);

  const onModalClose = useCallback(() => {
    setValues({});
    resetForm();
    setFiles({});
    setFilesToDelete([]);
    setStatusId(null);
    setCurrentStep(1);
    setIsSuccessful(false);
    window.location.reload();
    onClose();
  }, [onClose]);

  if (!open) {
    return null;
  }

  return (
    <>
      {showUpdateDialog && (
        <UpdateDialog
          open={showUpdateDialog}
          title="Организация уже подтверждена."
          subtitle="Хотите отредактировать данные?"
          loading={false}
          onClose={onModalClose}
          onConfirm={onConfirmToEdit}
        />
      )}
      <Modal
        title={modalTitle}
        onClose={onModalClose}
        open={open}
        dense
        {...restProps}
      >
        <ModalContainer>
          <StyledHeader>
            <StyledStepper>
              <Stepper
                steps={steps}
                disabled={false}
                currentStep={currentStep}
              />
            </StyledStepper>
          </StyledHeader>
          <StyledContent>
            <Dialog
              open={showDialog}
              title={`Вы уверены, что хотите удалить этот файл?`}
              icon={IconTrash24}
              onConfirm={onDeleteConfirm}
              onClose={() => {
                setShowDialog(false);
              }}
            />
            {currentStep === 1 && (
              <StepView1
                dataOfOrganization={formData}
                dataParams={formData}
                dataErrors={errors}
                onDataChange={onChange}
              />
            )}
            {currentStep === 2 && (
              <StepView2
                filterParams={formData}
                files={files}
                setFiles={setFiles}
                companyFileTypes={companyFileTypes}
                onFileDelete={onFileDelete}
                setIsStep2Saved={setIsStep2Saved}
              />
            )}
            {currentStep === 3 && (
              <StepView3 dataParams={formData} onDataChange={onChange} />
            )}
          </StyledContent>

          <StyledFooter>
            <StyledButtonBack>
              <Button
                text="Назад"
                variant="text"
                disabled={currentStep <= 1}
                showLoader={false}
                onClick={onPrevClick}
              />
            </StyledButtonBack>
            <ButtonRow>
              {currentStep === 1 && (
                <Button
                  text="Сохранить"
                  variant="outlined"
                  showLoader={pending}
                  onClick={onSaveClick}
                  disabled={isUpdateComplete}
                />
              )}
              {currentStep === 2 && (
                <Button
                  text="Сохранить"
                  variant="outlined"
                  showLoader={currentStep === 2 && pending}
                  onClick={onSaveDocuments}
                  disabled={!areFilesUploaded()}
                />
              )}
              {currentStep === 3 && (
                <Button
                  text="Сохранить"
                  variant="outlined"
                  showLoader={isSavingBankAccount}
                  onClick={onSaveBankAccount}
                />
              )}
              {currentStep === 1 && (
                <Button
                  text={"Далее"}
                  onClick={onNextClick}
                  disabled={data ? false : !isSuccessful}
                />
              )}
              {currentStep === 3 && (
                <Button
                  text={"Отправить на модерацию"}
                  onClick={onConfirmStatus}
                  showLoader={isConfirmingStatus}
                  disabled={!isBankAccountSaved || isConfirmingStatus}
                />
              )}
              {currentStep === 2 && (
                <Button
                  text="Продолжить"
                  onClick={onNextStep}
                  disabled={!areFilesUploaded() || !isStep2Saved}
                />
              )}
            </ButtonRow>
          </StyledFooter>
        </ModalContainer>
      </Modal>
    </>
  );
}

export default memo(ModalOrganizationCreate);
